export const queriesPreviewFallback: Record<string, any> = {
  es: [
    {
      query: 'sofá',
      title: 'Sofá'
    },
    {
      query: 'armario',
      title: 'Armario'
    },
    {
      query: 'colchón',
      title: 'Colchón'
    }
  ],
  pt: [
    {
      query: 'sofa',
      title: 'Sofa'
    },
    {
      query: 'roupeiro',
      title: 'Roupeiro'
    },
    {
      query: 'colchão',
      title: 'Colchão'
    }
  ]
};
