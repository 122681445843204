import { platformAdapter, PlatformResult, resultSchema } from '@empathyco/x-adapter-platform';
import { Result } from '@empathyco/x-types';
export const adapter = platformAdapter;

/* Code sample about how to extend the result mapper with more fields. */

interface EmpathyDemoPlatformResult extends PlatformResult {
  description: string;
  brandLogoUrl: string;
  energyIconUrl: string;
  discount: number;
  customLabel1Text: string;
  customLabel2Text: string;
  customLabel3Text: string;
  discountPercentage: string;
}

declare module '@empathyco/x-types' {
  export interface Result {
    description: string;
    brandLogoUrl: string;
    energyIconUrl: string;
    discount: string;
    customLabel1Text: string;
    customLabel2Text: string;
    customLabel3Text: string;
  }
}

resultSchema.$override<EmpathyDemoPlatformResult, Partial<Result>>({
  description: 'description',
  customLabel1Text: 'customLabel1Text',
  customLabel2Text: 'customLabel2Text',
  customLabel3Text: 'customLabel3Text',
  images: ({ __images }) => (Array.isArray(__images) ? __images.reverse() : [__images]),
  brandLogoUrl: 'brandLogoUrl',
  energyIconUrl: 'energyIconUrl',
  discount: 'discountPercentage'
});
